import { FC } from 'react';
import classNames from 'classnames';

import { useSelector } from 'lux/modules/useSelector';

import SpecialProjectsCompactItemItem from 'src/pages/Blog/components/SpecialProjects/SpecialProjectsCompactItem';

import blogGridStyles from '../blog-grid.less';

const BlogArticleGrid: FC = () => {
    const projects = useSelector(({ blogPage }) => blogPage.projects);

    return (
        <ul className={classNames(blogGridStyles.grid)}>
            {projects?.map((item, index) => (
                <li key={index} className={blogGridStyles.card}>
                    <SpecialProjectsCompactItemItem item={item} />
                </li>
            ))}
            {[0, 1, 2, 3].map((index) => (
                <li
                    key={`article-grid-end-placeholder-${index}`}
                    className={classNames(blogGridStyles.card, blogGridStyles.placeholder)}
                />
            ))}
        </ul>
    );
};

export default BlogArticleGrid;
