import { FC } from 'react';

import { usePush } from '@hh.ru/redux-spa-middleware';
import Select, { Option } from 'bloko/blocks/select';
import VSpacing from 'bloko/blocks/vSpacing';

import { BlogDictionaryItem } from 'lux/models/blogPage';
import { useSelector } from 'lux/modules/useSelector';

import styles from './blog-events-calendar.less';

const BlogEventsCalendarFilters: FC = () => {
    const eventsCalendar = useSelector(({ blogPage }) => blogPage.eventsCalendar);
    const push = usePush();

    const onFilterChange = (paramName: string, { target }: React.ChangeEvent<HTMLSelectElement>) => {
        const url = new URL(window.location.href);
        url.searchParams.set(paramName, target.value);
        push(url.href);
    };

    const renderFilter = (name: string, options?: BlogDictionaryItem[], activeOptionCode?: string) => {
        if (!options) {
            return null;
        }
        return (
            <div className={styles.eventsSelect} key={name}>
                <Select
                    onChange={(e) => {
                        onFilterChange(name, e);
                    }}
                    value={activeOptionCode}
                >
                    {options.map((option) => (
                        <Option value={option.code} key={option.code}>
                            {option.name}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    };

    return (
        <div className={styles.eventsFilters}>
            {renderFilter('region', eventsCalendar?.regionOptions, eventsCalendar?.region)}
            {renderFilter('prof', eventsCalendar?.profareaOptions, eventsCalendar?.profarea)}
            {renderFilter('type', eventsCalendar?.eventTypeOptions, eventsCalendar?.eventType)}
            <VSpacing base={3} />
        </div>
    );
};

export default BlogEventsCalendarFilters;
