import { FC, PropsWithChildren } from 'react';

import { BlogArticle, BlogArticleItemViewType } from 'lux/models/blogPage';

import ArticleListItemEvent from 'src/components/ArticleItem/ArticleListItemEvent';
import ArticleListItemLarge from 'src/components/ArticleItem/ArticleListItemLarge';
import ArticleListItemMedium from 'src/components/ArticleItem/ArticleListItemMedium';
import ArticleListItemMini from 'src/components/ArticleItem/ArticleListItemMini';
import ArticleListItemSpecial from 'src/components/ArticleItem/ArticleListItemSpecial';

type Props = {
    item: BlogArticle;
    viewType: (typeof BlogArticleItemViewType)[keyof typeof BlogArticleItemViewType];
    noPublicationInfo?: boolean;
};

const ArticleListItem: FC<Props & PropsWithChildren> = ({ viewType, ...props }) => {
    switch (viewType) {
        case BlogArticleItemViewType.Large:
            return <ArticleListItemLarge {...props} />;

        case BlogArticleItemViewType.Medium:
            return <ArticleListItemMedium {...props} />;

        case BlogArticleItemViewType.Special:
            return <ArticleListItemSpecial {...props} />;

        case BlogArticleItemViewType.Mini:
            return <ArticleListItemMini {...props} />;

        case BlogArticleItemViewType.Event:
            return <ArticleListItemEvent {...props} />;

        default:
            return null;
    }
};

export default ArticleListItem;
