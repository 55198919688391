import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Tag from 'bloko/blocks/tagList/tag';
import VSpacing from 'bloko/blocks/vSpacing';

import { BlogArticleItemStatic } from 'lux/models/blogPage';

import styles from './article-list-item.less';

type Props = {
    item: BlogArticleItemStatic;
};

const ArticleListItemPromo: FC<Props> = ({ item }) => (
    <BlokoLink Element={Link} to={item.href} kind={LinkKind.Inversed}>
        <div className={styles.promo} style={{ backgroundImage: `url(${item.preview})` }}>
            <div className={styles.overlay} />
            <div>
                {item.label && (
                    <div className={styles.label}>
                        <Tag>{item.label}</Tag>
                    </div>
                )}
                <VSpacing base={2} />
                <div className={styles.caption}>
                    <H3Section Element="h3">{item.title}</H3Section>
                </div>
            </div>
        </div>
    </BlokoLink>
);

export default ArticleListItemPromo;
