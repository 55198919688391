import { FC } from 'react';

import Column from 'bloko/blocks/column';

import BlogArticleList from 'src/pages/Blog/components/BlogArticleList';
import BlogCategoryLayout from 'src/pages/Blog/components/BlogCategoryLayout';

const BlogSearchPage: FC = () => {
    return (
        <BlogCategoryLayout showTopicsFilter={false}>
            <Column xs="4" s="8" m="8" l="12">
                <BlogArticleList />
            </Column>
            <Column xs="0" s="0" m="1" l="1" />
        </BlogCategoryLayout>
    );
};

export default BlogSearchPage;
