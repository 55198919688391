import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { ReadMoreArticle } from 'lux/models/articlePage';
import { BlogArticle } from 'lux/models/blogPage';
import { useSelector } from 'lux/modules/useSelector';

import { useGetArticleHref, getArticleImage } from 'src/components/ArticleItem/utils/get-article-properties';

import styles from './article-list-item.less';

type Props = {
    item: BlogArticle | ReadMoreArticle;
};

const ArticleListItemMedium: FC<Props & PropsWithChildren> = ({ item }) => {
    const articleId = useSelector((state) => state.articlePage?.id);
    const image = getArticleImage(item, 'readmore');
    const articleHref = useGetArticleHref(item, articleId);

    return (
        <div className={styles.medium}>
            {image && (
                <div className={styles.imageMediumWrapper}>
                    <BlokoLink Element={Link} to={articleHref}>
                        <img src={image} alt={item.title} className={styles.preview} />
                    </BlokoLink>
                </div>
            )}
            <div className={styles.caption}>
                <BlokoLink Element={Link} to={articleHref} kind={LinkKind.Tertiary} title={item.title}>
                    {item.title}
                </BlokoLink>
            </div>
        </div>
    );
};

export default ArticleListItemMedium;
