import { FC, PropsWithChildren } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'lux/modules/useSelector';
import Pagination from 'src/components/Pagination';

import BlogCategoryFilter from 'src/pages/Blog/components/BlogCategoryFilter';
import BlogHeader from 'src/pages/Blog/components/Header';

type Props = {
    showTopicsFilter: boolean;
};

const BlogCategoryLayout: FC<PropsWithChildren<Props>> = ({ children, showTopicsFilter }) => {
    const { paging } = useSelector(({ blogPage }) => blogPage);
    return (
        <>
            <Column xs="4" s="8" m="12" l="16" container>
                <VSpacing base={7} xs={0} />
                <Column xs="4" s="8" m="12" l="16">
                    <BlogHeader />
                </Column>
                <VSpacing base={8} xs={6} />
                {children}
                {showTopicsFilter && (
                    <Column xs="0" s="0" m="3" l="3">
                        <BlogCategoryFilter />
                    </Column>
                )}
            </Column>
            {paging && (
                <Column xs="4" s="8" m="12" l="16">
                    <VSpacing base={11} xs={14} />
                    <Pagination isSeoPaging {...paging} />
                    <VSpacing base={10} />
                </Column>
            )}
        </>
    );
};

export default BlogCategoryLayout;
