import { useCallback, useEffect, useRef, useState, FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import { ClickDown, MenuItem } from 'bloko/blocks/drop';
import { DownPlacement } from 'bloko/blocks/drop/Down/common';
import { BarsScaleMedium } from 'bloko/blocks/icon';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { BlogRubric, BlogTopic } from 'lux/models/blogPage';

import styles from './blog-header.less';

interface BlogDesktopMenuProps {
    rubrics: BlogRubric[];
}

const BlogDesktopMenu: FC<BlogDesktopMenuProps> = ({ rubrics }) => {
    const match = useRouteMatch<{ rubric: string; topic: string }>();
    const breakpoint = useBreakpoint(Breakpoint.L);
    const [mainMenuItems, setMainMenuItems] = useState(rubrics);
    const [dropdownMenuItems, setDropdownMenuItems] = useState<BlogTopic[]>([]);
    const isShowDropdownMenu = dropdownMenuItems.length > 0;
    const menuRef = useRef<HTMLDivElement>(null);
    const fakeMenuRef = useRef<HTMLDivElement>(null);

    const calculateItems = useCallback(() => {
        if (!menuRef.current || !fakeMenuRef.current) {
            return;
        }

        const fakeMenuWidth = fakeMenuRef.current.getBoundingClientRect().width;
        const menuWidth = menuRef.current.getBoundingClientRect().width;

        if (fakeMenuWidth > menuWidth) {
            let tmpWidth = 0;
            let count = 0;

            (fakeMenuRef.current.childNodes as never as HTMLAnchorElement[]).forEach((node) => {
                if (tmpWidth + node.clientWidth <= menuWidth - 50) {
                    tmpWidth += node.clientWidth;
                    count += 1;
                }
            });

            if (!dropdownMenuItems.length) {
                count -= 1;
            }

            if (count) {
                setMainMenuItems(rubrics.slice(0, count));
                setDropdownMenuItems(rubrics.slice(count));

                return;
            }
        }

        setMainMenuItems(rubrics);
        setDropdownMenuItems([]);
    }, [rubrics, dropdownMenuItems.length]);

    useEffect(() => {
        if (breakpoint !== Breakpoint.XS) {
            calculateItems();
        }
    }, [breakpoint, calculateItems]);

    if (!rubrics.length) {
        return null;
    }

    const renderItem = (item: BlogTopic) => {
        const isActive = match && (match.params.rubric === item.code || match.params.topic === item.code);
        const href = item.code === '_blog_index' ? '/articles' : `/articles/${item.code}`;

        return (
            <div
                className={classnames(styles.menuItem, {
                    [styles.menuItemActive]: isActive,
                })}
                key={item.code}
            >
                <BlokoLink Element={Link} to={href} kind={isActive ? undefined : LinkKind.Tertiary} disableVisited>
                    <Text>{item.name}</Text>
                </BlokoLink>
            </div>
        );
    };

    const renderSubItem = (item: BlogTopic) => {
        const isActive = match && (match.params.rubric === item.code || match.params.topic === item.code);
        const href = item.code === '_blog_index' ? '/articles' : `/articles/${item.code}`;

        return (
            <div key={item.code}>
                {isActive ? (
                    <MenuItem>
                        <span className={styles.menuItemActive}>{item.name}</span>
                    </MenuItem>
                ) : (
                    <BlokoLink Element={Link} to={href} kind={LinkKind.Tertiary}>
                        <MenuItem>
                            <Text>{item.name}</Text>
                        </MenuItem>
                    </BlokoLink>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.nav}>
                <div className={styles.menu} ref={menuRef}>
                    {mainMenuItems.map(renderItem)}
                </div>
                <div className={styles.fakeMenu} ref={fakeMenuRef}>
                    {rubrics.map(renderItem)}
                </div>
            </div>
            {isShowDropdownMenu && (
                <div className={styles.bars}>
                    <ClickDown
                        onlySetPlacement
                        placement={DownPlacement.BottomEnd}
                        render={() =>
                            dropdownMenuItems.map((item) => (
                                <div key={item.code} className={styles.menuItemWrapper}>
                                    {renderSubItem(item)}
                                </div>
                            ))
                        }
                    >
                        <div className={styles.burger}>
                            <BarsScaleMedium />
                        </div>
                    </ClickDown>
                </div>
            )}
        </div>
    );
};

export default BlogDesktopMenu;
