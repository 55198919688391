import { FC } from 'react';

import Column from 'bloko/blocks/column';

import { useSelector } from 'lux/modules/useSelector';
import ArticleListItemPromo from 'src/components/ArticleItem/ArticleListItemPromo';

import styles from './blog-rubric-article-list.less';

const BlogPromoList: FC = () => {
    const { data } = useSelector(({ blogPage }) => blogPage);

    if (!data?.promo?.items?.length) {
        return null;
    }

    return (
        <div className={styles.list}>
            {data.promo.items.map((item, index) => (
                <div key={`promo-${index}`} className={styles.itemContainer}>
                    <Column xs="4" s="4" m="6" l="8">
                        <ArticleListItemPromo item={item} />
                    </Column>
                </div>
            ))}
        </div>
    );
};

export default BlogPromoList;
