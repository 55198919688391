import { useState } from 'react';
import classnames from 'classnames';

import { H3Section } from 'bloko/blocks/header';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BlogTopic } from 'lux/models/blogPage';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import BlogSubMenuLink from 'src/pages/Blog/components/BlogSubMenuLink';

import styles from './blog-category-filter.less';

const CATEGORIES_LENGTH = 20;

const TrlKeys = {
    title: 'blog.filters.categories',
    more: 'blog.filters.categories.more',
};

const BlogCategoryFilter: TranslatedComponent = ({ trls }) => {
    const { rubrics, rubric, topic } = useSelector(({ blogPage }) => blogPage);
    const topics = rubrics.find(({ code }) => code === rubric)?.topics?.sort((a, b) => b.priority - a.priority) || [];
    const [categories, setCategories] = useState(topics.slice(0, CATEGORIES_LENGTH));

    if (!topics.length) {
        return null;
    }

    const renderTopic = (item: BlogTopic) => {
        const isActive = item.code === topic;
        const href = `/articles/${rubric as string}/${item.code}`;

        return (
            <div
                className={classnames(styles.menuItem, {
                    [styles.menuItemActive]: isActive,
                })}
                key={item.code}
            >
                <VSpacing base={2} />
                <BlogSubMenuLink isActive={isActive} href={href}>
                    <Text size={TextSize.Large}>{item.name}</Text>
                </BlogSubMenuLink>
                <VSpacing base={2} />
            </div>
        );
    };

    return (
        <div>
            <H3Section>{trls[TrlKeys.title]}</H3Section>
            <VSpacing base={2} />
            {categories.map(renderTopic)}
            {topics.length !== categories.length && (
                <BlokoLink appearance={LinkAppearance.Pseudo} onClick={() => setCategories(topics)}>
                    {trls[TrlKeys.more]}
                </BlokoLink>
            )}
        </div>
    );
};

export default translation(BlogCategoryFilter);
