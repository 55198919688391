import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BlogArticleItemViewType } from 'lux/models/blogPage';
import { SupernovaUserType } from 'lux/models/supernovaUserType';
import { useSelector } from 'lux/modules/useSelector';
import BlogArticleListItem from 'src/components/ArticleItem';
import translation from 'src/components/translation';

import styles from './blog-list.less';

type Props = {
    noPublicationInfo?: boolean;
    isEventsList?: boolean;
    withReadMoreLink?: boolean;
};

const TrlKeys = {
    more: 'blog.articles.list.more',
    noEvents: 'blog.articles.list.noEvents',
    noArticles: 'blog.articles.list.noArticles',
};

const BlogArticleList: TranslatedComponent<Props> = ({ trls, noPublicationInfo, isEventsList, withReadMoreLink }) => {
    const articles = useSelector(({ blogPage }) => blogPage.articles);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const moreLinkHref =
        supernovaUserType === SupernovaUserType.Employer ? '/articles/employers' : '/articles/applicants';

    if (!articles?.main?.length) {
        return <p className={styles.artlicleListEmpty}>{trls[isEventsList ? TrlKeys.noEvents : TrlKeys.noArticles]}</p>;
    }

    return (
        <>
            <ul className={styles.list}>
                {articles.main.map((item, index) => (
                    <li key={index} className={styles.listItem}>
                        {index !== 0 && <VSpacing base={6} xs={4} />}
                        <BlogArticleListItem
                            item={item}
                            viewType={isEventsList ? BlogArticleItemViewType.Event : BlogArticleItemViewType.Large}
                            noPublicationInfo={noPublicationInfo}
                        />
                        {index !== articles.main.length - 1 && <VSpacing base={6} xs={4} />}
                    </li>
                ))}
            </ul>
            {withReadMoreLink && (
                <>
                    <VSpacing base={11} xs={6} />
                    <BlokoLink disableVisited Element={Link} to={moreLinkHref}>
                        {trls[TrlKeys.more]}
                    </BlokoLink>
                </>
            )}
        </>
    );
};

export default translation(BlogArticleList);
