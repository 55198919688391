import { memo } from 'react';
import classNames from 'classnames';

import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ArticleRubric, ArticleRubrics, ArticleTopic } from 'lux/models/articlePage/types';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import styles from './tag.less';

interface ArticleTagProps {
    topicCode: ArticleTopic;
}

const RubricColors: Record<ArticleRubric, string | null> = {
    [ArticleRubrics.Applicants]: styles.tagRed,
    [ArticleRubrics.Employers]: styles.tagBlue,
    [ArticleRubrics.Students]: styles.tagGreen,
    [ArticleRubrics.LabourMarket]: styles.tagYellow,
    [ArticleRubrics.SiteNews]: styles.tagGrey,
    [ArticleRubrics.Insider]: styles.tagViolet,
    [ArticleRubrics.OwnBusiness]: styles.tagOrange,
    [ArticleRubrics.Specs]: null,
    [ArticleRubrics.Events]: null,
};

const DEFAULT_RUBRIC_COLOR = styles.tagGrey;

const ArticleTag: TranslatedComponent<ArticleTagProps> = ({ topicCode }) => {
    const { rubrics } = useSelector(({ blogPage }) => blogPage);
    const rubric = rubrics.find((rubric) => rubric.topics.find((topic) => topic.code === topicCode));
    const topic = rubric?.topics.find((topic) => topic.code === topicCode)?.name;

    if (!rubric || !topic) {
        return null;
    }

    return (
        <BlokoLink disableVisited kind={LinkKind.Tertiary} Element={Link} to={`/articles/${rubric.code}/${topicCode}`}>
            <div className={classNames(styles.tag, RubricColors[rubric.code as ArticleRubric] || DEFAULT_RUBRIC_COLOR)}>
                {topic}
            </div>
        </BlokoLink>
    );
};

export default translation(memo(ArticleTag));
