import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { LayoutCode } from 'lux/models/blogPage';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';
import categoryCodes from 'src/pages/Blog/categoryCodes';

import BlogRubricArticleList from 'src/pages/Blog/components/BlogRubricArticleList';

const TrlKeys = {
    title: 'articles.rubrics.insider',
    more: 'blog.articles.list.more',
};

const layoutCode = LayoutCode.Insider;

const BlogInsider: TranslatedComponent = ({ trls }) => {
    const { articles, layout } = useSelector(({ blogPage }) => blogPage);
    const articleListLayout = layout?.find(({ name }) => name === layoutCode);

    if (!articles.insider?.length) {
        return null;
    }

    return (
        <BlogRubricArticleList
            list={articles.insider}
            articleListLayout={articleListLayout}
            title={trls[TrlKeys.title]}
            more={trls[TrlKeys.more]}
            {...categoryCodes[layoutCode]}
        />
    );
};

export default translation(BlogInsider);
