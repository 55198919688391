import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BlogArticleItemViewType, LayoutCode } from 'lux/models/blogPage';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';
import categoryCodes from 'src/pages/Blog/categoryCodes';

import BlogRubricArticleList from 'src/pages/Blog/components/BlogRubricArticleList';

const TrlKeys = {
    title: 'articles.rubrics.events',
    more: 'blog.articles.events.more',
};

const layoutCode = LayoutCode.Events;

const BlogEventsSection: TranslatedComponent = ({ trls }) => {
    const { articles, layout } = useSelector(({ blogPage }) => blogPage);
    const articleListLayout = layout?.find(({ name }) => name === layoutCode);

    if (!articles?.events?.length) {
        return null;
    }

    return (
        <BlogRubricArticleList
            list={articles.events}
            articleListLayout={{
                ...(articleListLayout || { name: 'events' }),
                viewType: BlogArticleItemViewType.Mini,
            }}
            more={trls[TrlKeys.more]}
            title={trls[TrlKeys.title]}
            {...categoryCodes[layoutCode]}
        />
    );
};

export default translation(BlogEventsSection);
