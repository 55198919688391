import { useState, FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H2Section } from 'bloko/blocks/header';
import { ChevronScaleMediumKindRight, ChevronScaleMediumKindLeft, IconColor } from 'bloko/blocks/icon';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Slide from 'bloko/blocks/slide';
import Item from 'bloko/blocks/slide/Item';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useSelector } from 'lux/modules/useSelector';

import styles from './blog-slider.less';

const BlogArticlesSlider: FC = () => {
    const {
        data: { slider },
    } = useSelector(({ blogPage }) => blogPage);
    const [activeItem, setActiveItem] = useState(0);
    const breakpoint = useBreakpoint(Breakpoint.L);

    if (!slider?.items?.length) {
        return null;
    }

    const handleChangeSlide = (activeSlide: number) => {
        setActiveItem(activeSlide);
    };

    const handleClick = (activeSlide: number) => {
        let index = activeSlide;

        if (activeSlide < 0) {
            index = slider.items.length - 1;
        }

        if (activeSlide >= slider.items.length) {
            index = 0;
        }

        setActiveItem(index);
    };

    return (
        <div className={styles.container}>
            <Slide
                activeItem={activeItem}
                onChange={handleChangeSlide}
                transitionTiming={breakpoint === Breakpoint.XS ? 300 : 500}
            >
                {slider.items.map((item, index) => (
                    <Item key={index}>
                        <BlokoLink Element={Link} to={item.href} kind={LinkKind.Inversed} disableVisited>
                            <div
                                className={styles.slide}
                                style={item.preview ? { backgroundImage: `url("${item.preview}")` } : undefined}
                            >
                                <div className={styles.overlay} />
                                <div className={styles.slideContent}>
                                    <div className={styles.title}>
                                        <H2Section>{item.title}</H2Section>
                                    </div>
                                </div>
                            </div>
                        </BlokoLink>
                    </Item>
                ))}
            </Slide>
            {slider.items.length > 1 && (
                <div className={styles.sliderControlGroup}>
                    <div className={styles.sliderControl} onClick={() => handleClick(activeItem - 1)}>
                        <ChevronScaleMediumKindLeft initial={IconColor.White} />
                    </div>
                    <div className={styles.sliderControl} onClick={() => handleClick(activeItem + 1)}>
                        <ChevronScaleMediumKindRight initial={IconColor.White} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogArticlesSlider;
