import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import SpecialProjectsItem from 'src/pages/Blog/components/SpecialProjects/SpecialProjectsItem';

import styles from '../blog-list.less';

const TrlKeys = {
    more: 'blog.articles.list.more',
    noEvents: 'blog.articles.list.noEvents',
    noArticles: 'blog.articles.list.noArticles',
};

const SpecialProjectList: TranslatedComponent = ({ trls }) => {
    const projects = useSelector(({ blogPage }) => blogPage.projects);

    if (!projects?.length) {
        return <p className={styles.artlicleListEmpty}>{trls[TrlKeys.noArticles]}</p>;
    }

    return (
        <ul className={styles.list}>
            {projects.map((item, index) => (
                <li key={index} className={styles.listItem}>
                    {index !== 0 && <VSpacing base={6} xs={4} />}
                    <SpecialProjectsItem item={item} />
                    {index !== projects.length - 1 && <VSpacing base={6} xs={4} />}
                </li>
            ))}
        </ul>
    );
};

export default translation(SpecialProjectList);
